.container-all {
    /* border-bottom: 1px solid #e6e6e6; */
}
.constainer-menu {
    position: fixed;
    background-color: white;
    width: 100%;
    height: 0;
    top:0;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: height 0.5s;
}
.constainer-menu-full-height {
    height: 200px;
}
.container-custom {
    height: 45px;
    width: 100%;
    align-items: center;
    flex-direction: row;
    display: flex;
}
.container-categories {
    height: 45px;
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.logo-app {
    height: 35px;
}
.div-line {
    height: 1px;
    background-color: #e6e6e6;
}
.div-search {
    height: 35px;
    background-color: #F4F4F4;
    width: 100%;
    border-radius: 18px;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 18px;
    padding-right: 18px;
    display: flex;
    align-items: center;
}
.div-search-mobile {
    height: 35px;
    background-color: #F4F4F4;
    width: 100%;
    border-radius: 18px;
    padding-left: 18px;
    padding-right: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.div-search-none {
    flex: 1
}
.icon-search {
    height: 20px;
    width: 20px;
}
.div-menu {
    background-color: white;
    border: none;
}
.div-info {
}
.input-search {
    flex:  1;
    border: none;
    height: 35px;
    background-color: #F4F4F4;
    margin-left: 10px;
    font-size: 14px;
    font-family: nunito-sans;
}
.txt-nomal{
    font-family: nunito-sans;
    font-size: 14px;
    border-bottom: 0px none;
}
.txt-category{
    font-family: nunito-sans;
    font-size: 14px;
}
.div-paging {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 25px;
}
.button-paging {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #F4F4F4;
    border: none;
    margin-left: 5px;
    margin-right: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.marginHoz {
    margin-left: 5px;
    margin-right: 5px;
}
.txt-button {
    font-family: nunito-sans;
    font-size: 14px;
    color: #F15B67;
}
.button {
    height: 45px;
    justify-content: center;
    border: 1px solid #F15B67;
    background-color: white;
    display: flex;
    align-items: center;
}
.div-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.div-bottom {
    background-color: #F4F4F4;
}
.txt-policy{
    font-family: nunito-sans;
    font-size: 14px;
    text-align: center;
}