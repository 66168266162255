.container-product {
    margin-top: 15px;
    margin-bottom: 15px;
    /* transition:  0.5s; */
}
.image-product {
    width: 100%;
}
.txt-nomal{
    font-family: nunito-sans;
    font-size: 14px;
    overflow: hidden;
    line-height: 14px;
    max-height: 28px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.txt-button {
    font-family: nunito-sans;
    font-size: 14px;
    color: #F15B67;
    margin-left: 5px;
    margin-right: 5px;
}
.div-name-product {
    height: 28px;
    margin-bottom: 10px;
}
.button {
    height: 45px;
    justify-content: center;
    border: 1px solid #F15B67;
    background-color: white;
    display: flex;
    align-items: center;
    display: -webkit-flex;
    -webkit-align-items:center;
}